


@font-face {
  font-family: "Lapture";
  src: url('assets/LaptureSubhead/lapturesubhead_regular.ttf');
  src: local("Lapture")
    url('assets/LaptureSubhead/lapturesubhead_regular.ttf') format('truetype');
}

@font-face {
  font-family: "Montserrat";
  src: url('assets/Montserrat/Montserrat-VariableFont_wght.ttf');
  src: local("Montserrat")
    url('assets/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
}


/* Collor pallet */

:root{
  --lightest-col: rgb(248, 240, 229);
  --second-lightest-col: rgb(234, 219, 200);
  --second-darkest-col: rgb(218, 192, 163);
  --pallet-navy: rgb(15, 44, 89);
}



body{
  margin: 0;
  background-color: var(--lightest-col);
  /* width: 100%; */
}

p{
  font-family: Montserrat, Geneva, Tahoma, sans-serif;
  font-size: 1.2rem;
}

h1, h2{
  font-family: Lapture, 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}



.App {
  text-align: center;
}


.Nav-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}


.Footer{
  margin-top: auto;
  padding:  15px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--pallet-navy);
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
}

.Footer-text{
  font-size: small;
  margin:auto;
  color: var(--lightest-col);
  text-align: center;
  letter-spacing: .2rem;
}

.Footer-Icons-Wrapper{
  display: flex;
  height: 3rem;
  width: 30%;
  min-width: 400px;
  margin: auto;
  
}

.svg-wrapper{
  margin:auto;
  height:75%;
}
/* ##################################### */
/* ##############HOME PAGE############## */
/* ##################################### */

.Home-page{
  min-height: 100vh;
  max-width: 1920px;
  padding: 0 2%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  grid-template-areas:
    "side main"
    "gallery gallery";

}

.Home-side-wrapper{
  grid-area: side;
  /* border-radius: 1rem; */
  margin: 1rem 1.5rem 1rem 0;
  padding-right: 0.5rem;
  border-right: 2px solid rgb(52, 53, 68);
  /* background:linear-gradient(to bottom, transparent 0%, var(--second-darkest-col) 100%); */
}

.Home-side-content{
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;

  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.Home-side-content::-webkit-scrollbar{
  display: none;
}

.Side-bar-post-link{
  all: unset;
  cursor: pointer;
}

.External-link-sidebar{
  display: inline;
}

.side-bar-description{
  margin-top: 0;
}

.side-bar-item{
  padding: 0.5rem 1rem;
  /* margin-bottom: 1rem; */
  border: 10px; 
  transition: background-color .3s ease-in-out , box-shadow .3s ease-in-out;

}

.side-bar-item:hover{
  background-color: var(--second-lightest-col);
  box-shadow: inset 0px 0px 10px 10px var(--lightest-col);
}

.side-bar-highlight-item{
  /* border-radius: 1rem; */
  /* border-bottom: 2px dotted var(--pallet-navy); */
  /* background-color: var(--second-darkest-col); */
  padding: 1rem;
  height: fit-content;
  border-radius: 10px;
  transition: background-color .3s ease-in-out , box-shadow .3s ease-in-out;
}

.side-bar-highlight-item:hover{
  background-color: var(--second-lightest-col);
  box-shadow: inset 0px 0px 10px 10px var(--lightest-col);
}

.side-bar-image-wrapper{
  /* border-bottom: solid 2px black; */
  padding-bottom: 0.5rem;
}

/* #########HOME MAIN########## */

.Home-main-wrapper {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.Home-nav-item{
  padding: 0 1rem;

  transition: transform .3s ease-in-out;
  /* background-color: rgb(126, 0, 0); */
}

.Home-nav-item:hover{
  transform: scale(1.1);
}


.Home-title-container{
  text-align: center;
}

.Home-title-text { 
  /* font-family: Lapture; */

  margin: 0;
  letter-spacing: -0.27rem;
  font-size: 6rem;
  font-weight: 500;
}

.Home-bio{
  width: 100%;
}

.Home-bio-text-wrapper{
  word-wrap: break-word;
  overflow-y: auto;
  /* height: auto; */

}

.Home-bio-img{
  background-color:  rgb(155, 155, 155);
  width: 100%;
  /* min-height: 600px; */
}



/* #########GALLERY######## */

.Home-gallery{
  grid-area: gallery;
  /* background-color: rgb(63, 9, 9); */
  padding: 10px 3rem;
}

.Gallery-item-container{
  display: flex;
  
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}

.Gallery-item{
  width: 20rem;
  height: 20rem;
  min-width: 200px;
  min-height: 200px;
  /* background-color: grey; */
  margin: 4rem 5%;

}

.Gallery-item-img{
  transition: transform .3s ease-in-out, box-shadow .3s ease-in-out;

}

.Gallery-item-img:hover{
  transform: scale(1.1);
  box-shadow: 10px 5px 5px var(--second-darkest-col);
}

.Gallery-item img{
  width: 100%;
  /* height: 100%; */
}

/* ##################################### */
/* ##############BLOG PAGE############## */
/* ##################################### */




.Blog-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
} 

.Blog-posts-container{
  padding-bottom: 150px;
}

.Blog-post-container {
  margin: auto;
  border-bottom: 2px dashed rgb(146, 146, 146);
  width: 75%;
  min-width: 300px;
  max-width: 1000px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.Blog-title {
  text-align: center;
  padding-top: 1rem;
}

.Blog-post-title{
  margin-bottom: 0;
}

.Blog-publish-date{
  font-size:90%;
  margin: 0
}

.Blog-post-content {
  white-space: pre-wrap;
}

/* ##################################### */
/* ############CONTACT PAGE############# */
/* ##################################### */
button,
input,
select,
label,
textarea {
  font-family: Montserrat, Geneva, Tahoma, sans-serif;
  font-size: 100%;
}


.Contact-page{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.Contact-form-wrapper{
  width: 50%;
  min-width: 300px;
  max-width: 1000px;
  margin:auto;
  height: 440px;
  padding: 4rem 0;
}

#contact-form{
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  max-width: 700px;
  margin:auto;
  height: 100%;
}

.Label-text-container{
  /* margin: auto; */
  padding-top: 1rem; 
  display: flex;
  flex-direction: row;
}

.Input-text{
  -webkit-appearance: none;
  margin-left: 10px;
  flex-grow: 2;
  border: none;
  background-color: rgba(0,0,0,0);
  border-bottom: 2px dotted var(--second-darkest-col);
}

.Label-text-area-container{
  /* margin: auto; */
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  min-height: 6.25rem;
  height: 100%;
  padding-top: 1rem;
}

.Label-text-area-container>label{
  margin-bottom: auto;
  text-wrap: nowrap;
}
.Label-text-area-container>textarea{
  height:100%;
  width: 100%;
  resize: none;
  flex-grow: 2;
  font-size: 80%;
  margin: 0 0 10px 10px;
  background-color: rgba(0,0,0,0);
  box-shadow: 10px 5px 5px var(--second-darkest-col);
}




#form-send-button{
  -webkit-appearance: none;
  color: var(--lightest-col);
  border-radius: .5rem;
  width: 4rem;
  margin-top: 30px;
  align-self: flex-end;
  background-color: var(--pallet-navy);
}


/* ############################################################################################################### */
/* ############################################################################################################### */
/* PHONE STUFF */
/* ############################################################################################################### */
/* ############################################################################################################### */

@media (orientation: portrait) and (max-width: 950px), (max-width: 800px){
  /* ##################################### */
  /* #############HOME PAGE############### */
  /* ##################################### */
    body{
      display:flex; 
      flex-direction:column; 
      justify-content:center;
    }
    :root{
      width: 100%;
      font-size: 90%;
    }

    .Home-page{
      display: flex;
      flex-direction: column;
      padding:none;
    }

    .Home-main-wrapper{
      order: 1;
    }

    .Nav-container{
      flex-wrap: wrap;
    }
    .Home-bio{
      order: 3;
    }

    .Home-side-wrapper{
      order: 2;
      border-right: none;
      border-top: 2px solid rgb(52, 53, 68);
      margin: 1rem 0;
      padding: 0 10px;
      box-shadow: inset 0px 0px 10px 10px var(--lightest-col);
    }

    .Home-side-content{
      overflow: none;
      max-height: none;
      height: fit-content;
    }

    .post-item-link:hover{
      background-color: rgba(0,0,0,0);
      box-shadow: none;
    }

    .Home-gallery{
      order: 3;
    }

    .Gallery-item{
      min-width: 0;
      min-height: 0;
      /* height: 0; */
      max-width: 20rem;
      width: 100%;
    }

    #form-send-button{
      width: fit-content;
      font-weight: normal;
      /* padding: .25rem 0; */
    }

    .Label-text-area-container>textarea{
      border: 2px dotted var(--second-darkest-col);
    }

  /* ##################################### */
  /* #############FOOTER################## */
  /* ##################################### */
    
  
    .Footer-Icons-Wrapper{
      min-width: 150px;
      flex-wrap: wrap;
      width:75%;
      height: 5rem;
      padding-bottom: 5px;
    }

    .Footer-text{
      flex: 0 0 100%;
      padding-bottom: 15px;
    }

    .svg-wrapper{

      max-width: 60px;
      height: 45%;
      order: 2;
      /* margin: auto; */
    }
    
}